import { PartitionKeyIdPair } from "@Core/Lib/model";
import { SmDateFormats } from "@Shared/Pipes/sm-date.pipe";

export interface SmFormVM {
    Id: string; // The Id of the App
    Sections: SmFormSectionVM[];
}

export interface SmFormElementVM {
    Id: string;
    Label: string;
    PartitionKey: string;
    ElementType: 'Section' | 'Field' | 'StaticText' | 'Row'; // Note: Row is not actually an 'Element' yet, 
                                                             // so some of these properties won't apply to a Row. 
    CreatedFromId: string;
    Description?: string;
    Hidden?: boolean; // Used for conditionally hiding elements. 
    Errors?: string[];
}

export interface SmFormSectionVM extends SmFormElementVM {
    Items: SmFormElementVM[];
    ParentId: string; // The id of its parent
    ParentPartitionKeyIdPair: PartitionKeyIdPair;
    ToggledToHide?: boolean;
    
    Repeatable: boolean;
    MinRepeatableInstances?: number;
    MaxRepeatableInstances?: number;
    Instances?: SmFormSectionInstanceVM[];
    RepeatableVisibleLabels?: string[];
    ParentIsDeleted?: boolean; // If this section is a nested repeatable and its parent is deleted
    Access?: string; // 'Normal' | 'PreventAddAndDelete' 
}

// An instance of a repeatable section
export interface SmFormSectionInstanceVM {
    Id: string;
    PartitionKey: string;
    CreatedFromId: string;
    ParticipationStatus?: 'Added' | 'Changed' | 'Deleted'; // Null means unchanged
    Items?: SmFormElementVM[];
    ToggledToHide?: boolean;
    Errors?: string[];
    Access?: string; // 'Normal' | 'PreventAddAndDelete' 
}

export interface SmFormFieldVM extends SmFormElementVM {
    Required: boolean;
    Value: any;
    PreviousValue?: any;
    FieldType: SmFormTypes; // string, date, etc.
    HelpText?: string;
    Disabled?: boolean; // In edit mode, whether or not the input is disabled.
    IncludeInRepeatable?: boolean;
    Options?: SmFormFieldOptionsVM;
    Errors?: string[];
    FormWidth?: FormWidths;
    Prefix?: string;
    Suffix?: string;
    SpecialType?: 'Currency' | null
}

export interface SmFormFieldOptionsVM  {
    ShowCommas?: boolean;               // Integer, whether to use 1,000,123 or 10001233

    NumberOfDecimals?: number;          // Decimal
    DisplayAsPercentage?: boolean;      // Decimal
    DisplayNegativesInRed?: boolean;    // Decimal & Money
    Separator?: string;                 // Decimal

    Currency?: string;                  // Money (ONLY use if overriding the default currency for the program)
    DisplayAsWholeNumber?: boolean;     // Money

    Options?: string[];                 // MultiSelect (null for not allowing custom), Select or RadioOptions
    AllowSpaces?: boolean;              // MultiSelect

    DateFormat?: SmDateFormats;         // Date

    MultiLineEntry?: boolean;           // String
    MinLength?: number;                 // String
    MaxLength?: number;                 // String
    ButtonAlignment?: string;           // Radio

    ShowUrlInViewOnly?: boolean;        // URL
}


export interface SmFormStaticElementVM extends SmFormElementVM {
    Text: string;
}

export interface SmFormRowVM extends SmFormElementVM {
    // Note: A Row is not a real Element of an Application, so some of the
    // base properties will not be applicable

    Fields: SmFormFieldVM[]; 
    SpecialType: 'Currency' | null; // Some rows have specific behavior beyond just the display
}

export interface SmFormState {
    Edition: number;
    Currency: string;
    IsStartingOrCopy: boolean;
}

export enum SmFormTypes {
    Boolean = "boolean",
    ContractDate = "contractDate",
    Decimal = "decimal",
    Email = "email",
    Integer = "integer",
    MultiSelect ="multi-select",
    Money = "money",
    Phone = "phone",
    RadioOptions = "radio-options",
    Select = "select",
    String = "string",
    Timestamp = "timestamp",
    Url = "url",
    Year = "year",
    Zip = "zip"
}

export enum FormWidths {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    ExtraLarge = "Extra Large"
}

// #region Events

export interface FormValueChangeEvent {
    FieldVM: SmFormFieldVM;
    NewValue: any;
    Type?: 'input' | 'change';
}

export interface SectionDeletedEvent {
    InstanceId: string;
    InstancePartitionKey: string;
    ContainingSectionId: string;
}

export interface SectionAddedEvent {
    InstanceId: string;
    ContainingSectionId: string;
}


export interface SmElementChangedEvent {
    ElementId: string;
    ElementVM: SmFormElementVM;
}

export interface SmSectionAddedEvent {
    SectionVM: SmFormSectionVM;
}

export interface SmOpenSectionInstanceEvent {
    InstanceId: string;
}

export interface SmSectionDeletedEvent {
    SectionVM: SmFormSectionVM;
    InstanceId: string;
}

export interface SmElementFocusedEvent {
    Id: string;
    // Field: an invalid/required field
    // Section: a max/min error on the FE representation of the repeatable section grouping. 
    // ParentSection: This is the parent of the min/max issue (this is the Id the backend stores)
    Type: 'Section' | 'Field' | 'ParentSection'; 
}

// #endregion Events