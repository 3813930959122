import { Component, Input } from "@angular/core";
import { SmFormFieldVM } from "@Shared/Components/sm-form/sm-form.model";

@Component({
    selector: 'sm-form-field-label',
    templateUrl: './sm-form-field-label.component.html',
    styleUrls: ['./sm-form-field-label.component.scss']
})
export class SmFormFieldLabelComponent {
    @Input() model: SmFormFieldVM;
}