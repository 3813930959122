import { EventEmitter, Component, Input, Output, ElementRef } from "@angular/core";
import { FormValueChangeEvent, SmFormFieldVM, SmFormState, SmFormTypes} from "@Shared/Components/sm-form/sm-form.model";
import { InfoStackRow, InfoLine, InfoLineState } from "@Shared/Components/sm-info-stack/sm-info-stack.component";
import { SmFormService } from "../sm-form-service";
import { Subscription } from "rxjs";
import _ from "lodash";

@Component({
    template: ''
})
export class SmFormFieldBase {

    @Input() model: SmFormFieldVM;
    @Input() readOnly: boolean = true;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onFocusChanged: EventEmitter<boolean> = new EventEmitter();

    public infoStackState: InfoLineState = 'Normal';
    private elementFocused$: Subscription;

    constructor(protected service: SmFormService, protected element: ElementRef) { }

    ngOnInit() {
        this.elementFocused$ = this.service.getElementFocused().subscribe(e => {
            if (e.Id == this.model.Id ) {
                this.putFocusIntoInput();
            }
        });
    }

    ngOnDestroy() {
        this.elementFocused$?.unsubscribe();
    }

    public valueChange(e: any, type?: 'input' | 'change') {
        var vm: FormValueChangeEvent = {
            FieldVM: this.model,
            NewValue: this.model.Value,
            Type: type
        }
        this.onValueChanged.emit(vm);
    }

    public getInputIdOrName() {
        return "Field:" + this.model.Id;
    }

    public getInputCssClass() {
        let classes: string[] = [];

        if (!this.readOnly) {

            // Field width
            let formWidth: string;
            if (this.model.FormWidth) {
                formWidth = this.model.FormWidth.split(' ').join('').toLowerCase();
            } else {
                // Default to extra large if none was passed in
                formWidth = "extralarge";
            }
            classes.push("field--inputWidth-" + formWidth);

            // invalid
            if (this.model.Errors?.length) {
                classes.push('is-invalid');
            }
        }

        return classes;
    }

    public onRevertToPreviousValue() {
        this.model.Value = this.model.PreviousValue;
        this.valueChange(null);
    }

    public fieldHasPreviousValue() {
        return this.service.fieldHasPreviousValue(this.model, this.formState);
    }

    public getPreviousValue() {
        return this.formatFieldValue(this.model.PreviousValue);
    }

    // The ViewModel for the Info Stack when the field is in readOnly mode
    // Override this in the field-specific component if a different info stack model is needed
    public getInfoStack(): InfoStackRow[] {
        var classes = [];
        if (this.model.Options?.DisplayNegativesInRed && Number(this.model.Value) < 0)
            classes.push('text-danger');

        var infoLines: InfoLine[] = [
            {
                State: this.infoStackState,
                MainText: this.formatFieldValue(this.model.Value),
                Classes: classes,
                InternalValue: this.model.FieldType == SmFormTypes.Url ? this.model.Value : null
            }
        ];

        if (this.fieldHasPreviousValue()) {
            // Clone classes so we don't change the the classes for the main text.
            var prevValueClasses = _.clone(classes);
            prevValueClasses.push('previous_value');
            infoLines.push({
                State: 'Italic',
                MainText: 'Previous: ' + this.formatFieldValue(this.model.PreviousValue),
                Classes: prevValueClasses,
                InternalValue: this.model.FieldType == SmFormTypes.Url ? this.model.PreviousValue : null
            });
        }

        return  [
            {
                'Label': this.model.Label,
                'ForForm': true,
                'InfoLines': infoLines
            }
        ];
    }

    protected putFocusIntoInput() {
        this.element.nativeElement.querySelector('input').focus()
    }

    protected formatFieldValue(value) {
        return this.service.formatFieldValue(value,
            this.model.FieldType,
            this.model.Options,
            this.formState,
            this.model.Prefix,
            this.model.Suffix);
    }
}