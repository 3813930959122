import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import _ from "lodash";
import { SmFormRowVM, SmFormState, FormValueChangeEvent } from "../sm-form.model";

@Component({
    selector: 'sm-form-row',
    templateUrl: './sm-form-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormRowComponent  {
    // This is an element that displays its fields inline. 

    @Input() model: SmFormRowVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();
}