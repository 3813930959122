import _ from "lodash";
import { Field } from "@Core/CodeGen/Models/configuration.models";
import { FormWidths, SmFormFieldVM, SmFormTypes } from "./sm-form.model";

export class SmFormHelper {

    public static GetRecommendedWidth(field: Field): FormWidths {
        switch (field.Type) {
            case "DecimalField":
            case "YearField":
                return FormWidths.Small;
            case "DateField":
            case "IntegerField":
            case "MoneyField":
            case "PhoneField":
            case "ZipcodeField":
                return FormWidths.Medium;
            case "EmailField":
                return FormWidths.Large;
            case "StringField":
            case "ListOfStringsField":
            case "TimestampField":
            case "UrlField":
            case "YesNoField":
                return FormWidths.ExtraLarge;
            default:
                return FormWidths.ExtraLarge;
        }
    }

    // Return the real value for a field
    // I.e. we want a number for a Integer, Decimal, Year, or Money field
    public static GetActualValue(field: SmFormFieldVM): any {
        switch (field.FieldType) {
            case SmFormTypes.Decimal:
            case SmFormTypes.Year:
            case SmFormTypes.Integer:
            case SmFormTypes.Money:
                if (!field.Value)
                    return 0;

                var value = field.Value;
                // Cut off the first character if its a currency symbol. We can't convert it to a number with it.
                if (_.isString(value) && _.isNaN(Number(value[0])) && value[0] != "-")
                    value = value.substring(1);
                
                var num = Number(value);
                
                if (_.isNaN(num))
                    return 0;
                return num;   
            case SmFormTypes.ContractDate:
                if (!field.Value)
                    return "";

                var date = new Date(field.Value);
                return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate())
                    .padStart(2, '0');        
            default:
                return field.Value;
        }
    }
}
