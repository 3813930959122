import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from "rxjs";
import { share } from 'rxjs/operators';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { StartWorkflowDTOProduct } from '@Services//account-workflow-service';
import { LoadingService } from '@Services//loading-service';
import { LoggingService } from '@Services//logging-service';

import {
    Account, AccountActivity, AccountComment, AccountJournalEntry,
    AccountRole as AccountAccountRole, Assignable as AccountAssignable, Policy,
    PolicySet, RuleSetRiskAssessment, Transaction
} from '@Core/CodeGen/Models/area.models';
import { AccountRole, Assignable, WorkflowSet } from '@Core/CodeGen/Models/area.models';
import { AccountContext } from '@Core/Lib/Contexts/account-context';
import { ContractDate } from '@Core/Lib/contract-date';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { ModelUtils } from "@Core/Lib/Utils/model-utils";
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { IServiceResponse, ITypedServiceResponse } from '@Core/Lib/model';

class Response extends HttpResponse<any> { }

@Injectable()
export class AccountsService
    extends BaseService {

    private serviceUrls = class ServiceUrls {
        public static baseUrl: string = BaseService.baseUrl + '/Accounts/';
        public static transactionsTemplate: string = ServiceUrls.baseUrl + '{{accountId}}/Transactions/';
        public static transactionsListTemplate: string = ServiceUrls.baseUrl + 'Transactions';
        public static accountsSearchTemplate: string = ServiceUrls.baseUrl + 'Search';

        public static baseGetUrlSubstituted: string = ServiceUrls.baseUrl + '{{accountId}}/{{item}}';
        public static baseGetUrl(accountId: string, item: string): string {
            return (ServiceUrls.baseGetUrlSubstituted as string).replace("{{accountId}}", accountId).replace("{{item}}", item);
        }

        public static basePostUrlSubstituted: string = ServiceUrls.baseUrl + '{{accountId}}/{{item}}';
        public static basePostUrl(accountId: string, item: string): string {
            return (ServiceUrls.basePostUrlSubstituted as string).replace("{{accountId}}", accountId).replace("{{item}}", item);
        }

        public static basePutUrlSubstituted: string = ServiceUrls.baseUrl + '{{accountId}}/{{item}}/{{itemId}}';
        public static basePutUrl(accountId: string, item: string, itemId: string): string {
            return (ServiceUrls.basePutUrlSubstituted as string).replace("{{accountId}}", accountId).replace("{{item}}", item)
                .replace("{{itemId}}", itemId);
        }

        public static activities: string = ServiceUrls.baseUrl + 'Activities';

        public static transactionList(): string {
            return ServiceUrls.transactionsListTemplate;
        }

        private static riskDetailsUnsubstituted: string = BaseService.baseUrl + '/Data/{{workflowSetId}}/RiskDetail';
        public static riskDetails(workflowSetId: string): string {
            return (ServiceUrls.riskDetailsUnsubstituted as string).replace("{{workflowSetId}}", workflowSetId)
        }

        public static listPolicies(): string {
            return ServiceUrls.baseUrl + 'Policies';
        }

        private static TransactionLaunchUrlUnsubstituted: string = ServiceUrls.baseUrl + "{accountId}/Transactions/{transactionId}/Launch";
        public static TransactionLaunchUrl(accountId: string, transactionId: string) {
            return this.TransactionLaunchUrlUnsubstituted
                .replace("{accountId}", accountId)
                .replace("{transactionId}", transactionId);
        }

        private static RetrieveTransactionUrlUnsubstituted: string = ServiceUrls.transactionsTemplate + "{transactionId}";
        public static RetrieveTransactionUrl(accountId: string, transactionId: string){
            return this.RetrieveTransactionUrlUnsubstituted
            .replace("{accountId}", accountId)
            .replace("{transactionId}", transactionId);
        }

        private static ChangeSelectionUnsubstituted: string = ServiceUrls.baseUrl + '{accountId}/PolicySets/{policySetId}/ProductSelection';
        public static ChangeSelection(accountId: string, policySetId: string) {
            return this.ChangeSelectionUnsubstituted
                .replace("{accountId}", accountId)
                .replace("{policySetId}", policySetId);
        }

        private static PoliciesUnsubstituted: string = ServiceUrls.baseUrl + '{accountId}/Policies/{id}';
        public static Policies(accountId: string, policyId: string) {
            return this.PoliciesUnsubstituted
                .replace("{accountId}", accountId)
                .replace("{id}", policyId);
        }

        private static WorkflowSetsUnsubstituted: string = ServiceUrls.baseUrl + '{accountId}/WorkflowSets';
        public static WorkflowSets(accountId: string) {
            return this.WorkflowSetsUnsubstituted
                .replace("{accountId}", accountId);
        }
    }

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    // #region Accounts

    public loadAccount(accountContext: DataContext, accountId: string, isInteractive: boolean): Observable<Response> {
        const interactiveHeader = this.getUserInteractionHeader(isInteractive);
        const request = this.http.get<any>(`${this.serviceUrls.baseUrl}${accountId}`,
            { headers: interactiveHeader })
            .pipe(share());
        this.handleNormalGetRequest(Account, request, accountContext);
        return request;
    }

    // #endregion Accounts

    // #region Policies

    public loadPoliciesByWorkflow(context: AccountContext, workflowId: string, workflowSetId: string): Observable<Response> {
        const url = this.serviceUrls.listPolicies();
        const params = new HttpParams()
            .set("accountId", context.accountId)
            .set("workflowId", workflowId)
            .set("workflowSetId", workflowSetId)
            .set("includeClosed", true);
        const request = this.http.get<any>(url, { params }).pipe(share());
        this.handleNormalGetRequest(Policy, request, context);
        return request;
    }

    public loadPoliciesByAccount(context: AccountContext, accountId: string): Observable<Response> {
        const url = this.serviceUrls.listPolicies();
        const params = new HttpParams()
            .set("accountId", accountId)
            .set("includeClosed", true);
        const request = this.http.get<any>(url, { params }).pipe(share());
        this.handleNormalGetRequest(Policy, request, context);
        return request;
    }

    public updateCommission(accountId: string, policyId: string, commissionDTO: CommissionDTO, accountContext: AccountContext)
        : Observable<Response> {

        var url = this.serviceUrls.Policies(accountId, policyId) + '/UpdateCommission';
        let request = this.http.put<any>(url, commissionDTO).pipe(share());
        this.handleNormalPostPutRequest(Policy, request, accountContext);
        return request;
    }

    public revertPolicyCommission(accountId: string, policyId: string, accountContext: AccountContext): Observable<Response> {
        var url = this.serviceUrls.Policies(accountId, policyId) + '/ResetCommission';
        let request = this.http.put<any>(url, null).pipe(share());
        this.handleNormalPostPutRequest(Policy, request, accountContext);
        return request;
    }

    // #endregion Policies

    public transactionListFilters: string = "";

    public loadTransactions(accountId: string, accountContext: AccountContext, isInteractive: boolean): Observable<Response> {
        const url = this.serviceUrls.transactionList();
        let params = new HttpParams().set("accountId", accountId);
        params = params.set("includeDeselected", true);
        const interactiveHeader = this.getUserInteractionHeader(isInteractive);
        
        const request = this.http.get<any>(url, { headers: interactiveHeader, params }).pipe(share());
        this.handleNormalGetRequest(Transaction, request, accountContext);
        return request;
    }

    public loadTransactionsByWorkflow(workflowId: string, workflowSetId: string, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.transactionList();
        const params = new HttpParams()
            .set("workflowId", workflowId)
            .set("workflowSetId", workflowSetId);
        const request = this.http.get<any>(url, { params }).pipe(share());
        this.handleNormalGetRequest(Transaction, request, accountContext);
        return request;
    }

    public retrieveTransaction(accountId: string, transactionId: string, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.RetrieveTransactionUrl(accountId, transactionId);
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Transaction, request, accountContext);
        return request;
    }

    public loadPolicySets(accountId: string, accountContext: AccountContext, isInteractive: boolean): Observable<Response> {
        const interactiveHeader = this.getUserInteractionHeader(isInteractive);
        const request = this.http.get<any>(this.serviceUrls.baseGetUrl(accountId, 'PolicySets'), 
            {
                headers: interactiveHeader,
                params: { includeDeleted: true, includeClosed: true }
            })
            .pipe(share());
        this.handleNormalGetRequest(PolicySet, request, accountContext);
        return request;
    }

    public loadActivities(accountId: string, accountContext: AccountContext): Observable<Response> {
        const params = new HttpParams().set("accountId", accountId);

        const request = this.http.get<any>(this.serviceUrls.activities, {
            params: params
        }).pipe(share());

        this.handleNormalGetRequest(AccountActivity, request, accountContext);
        return request;
    }

    public loadAssignables(context: AccountContext): Observable<Response> {
        const url = this.serviceUrls.baseGetUrl(context.accountId, "Assignables");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Assignable, request, context);
        this.handleNormalGetRequest(AccountAssignable, request, context);
        return request;
    }

    //#region Account Roles

    public assignAccountRole(roleId: string, identityId: string, context: AccountContext): Observable<Response> {
        const url = this.serviceUrls.basePutUrl(context.accountId, "AccountRoles", roleId) + '/Assign';
        let params = new HttpParams();
        if (identityId) {
            params = params.set("identityId", identityId);
        }

        if (!identityId) {
            var role = context.get(ModelUtils.createDomainId(new AccountRole(), roleId)) as AccountRole;
            var edges = role.HasMembers();
            if (edges) {
                context.remove(edges[0]);
            }
        }

        const request = this.http.put<any>(url, null, { params }).pipe(share());
        this.handleNormalPostPutRequest(AccountRole, request, context);
        this.handleNormalPostPutRequest(AccountAccountRole, request, context);

        return request;
    }

    //#endregion Account Roles

    //#region Journals

    loadJournalEntries(accountId: string, accountContext: AccountContext): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.baseGetUrl(accountId, "JournalEntries")).pipe(share());
        this.handleNormalGetRequest(AccountJournalEntry, request, accountContext);
        return request;
    }

    saveOrUpdateJournalEntry(accountId: string, journal: AccountJournalEntry, accountContext: AccountContext)
        : Observable<ITypedServiceResponse<AccountJournalEntry>> {
        if (journal.Id) {
            return this.updateJournalEntry(accountId, journal, accountContext);
        } else {
            return this.saveJournalEntry(accountId, journal, accountContext);
        }
    }

    saveJournalEntry(accountId: string, journal: AccountJournalEntry, accountContext: AccountContext)
        : Observable<ITypedServiceResponse<AccountJournalEntry>> {
        const url = this.serviceUrls.basePostUrl(accountId, "JournalEntries");

        // TODO SM-3738: Use serialize() method once its implemented
        var serializedJournal = {
            ExternalCommunication: journal.ExternalCommunication,
            Message: journal.Message,
            Subject: journal.Subject,
            Tags: journal.Tags,
            AccountId: journal.AccountId,
            AffectedUsers: journal.AffectedUsers,
            Action: journal.Action,
            EntityType: journal.EntityType,
            EntityId: journal.EntityId,
            ActivityTime: journal.ActivityTime,
            IdentityKey: journal.IdentityKey,
            "@Type": "AccountJournalEntry"
        }
        const request = this.http.post<any>(url, serializedJournal).pipe(share());
        this.handleNormalPostPutRequest(AccountJournalEntry, request, accountContext);
        return request;
    }

    updateJournalEntry(accountId: string, journal: AccountJournalEntry, accountContext: AccountContext)
        : Observable<ITypedServiceResponse<AccountJournalEntry>> {
        const url = this.serviceUrls.basePutUrl(accountId, "JournalEntries", journal.Id);

        // TODO SM-3738: Use serialize() method once its implemented
        var serializedJournal = {
            Id: journal.Id,
            ExternalCommunication: journal.ExternalCommunication,
            Message: journal.Message,
            Subject: journal.Subject,
            Tags: journal.Tags,
            AccountId: journal.AccountId,
            AffectedUsers: journal.AffectedUsers,
            Action: journal.Action,
            EntityType: journal.EntityType,
            EntityId: journal.EntityId,
            ActivityTime: journal.ActivityTime,
            IdentityKey: journal.IdentityKey,
            "@Type": "AccountJournalEntry"
        }
        const request = this.http.put<any>(url, serializedJournal).pipe(share());
        this.handleNormalPostPutRequest(AccountJournalEntry, request, accountContext);
        return request;
    }

    //#endregion

    //#region comments

    loadComments(accountId: string, accountContext: AccountContext): Observable<IServiceResponse> {
        const request = this.http.get<any>(this.serviceUrls.baseGetUrl(accountId, 'Comments')).pipe(share());
        this.handleNormalGetRequest(AccountComment, request, accountContext);
        return request;
    }

    addComment(accountId: string, comment: AccountComment, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.basePostUrl(accountId, "Comments");

        // TODO SM-3738: Use serialize() method once its implemented
        const serializedComment = {
            Message: comment.Message,
            AccountId: comment.AccountId,
            AffectedUsers: comment.AffectedUsers,
            Action: comment.Action,
            EntityType: comment.EntityType,
            EntityId: comment.EntityId,
            ActivityTime: comment.ActivityTime,
            IdentityKey: comment.IdentityKey,
            EntityCorrelationKey: comment.EntityCorrelationKey,
            TaskId: comment.TaskId,
            ActivityType: comment.ActivityType,
            WorkflowSetId: comment.WorkflowSetId,
            "@Type": "AccountComment"
        }
        const request = this.http.post<any>(url, serializedComment).pipe(share());
        this.handleNormalPostPutRequest(AccountComment, request, accountContext);
        return request;
    }

    updateComment(accountId: string, comment: AccountComment, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.basePutUrl(accountId, "Comments", comment.Id);

        // TODO SM-3738: Use serialize() method once its implemented
        const serializedComment = {
            Id: comment.Id,
            Message: comment.Message,
            AccountId: comment.AccountId,
            AffectedUsers: comment.AffectedUsers,
            Action: comment.Action,
            EntityType: comment.EntityType,
            EntityId: comment.EntityId,
            ActivityTime: comment.ActivityTime,
            IdentityKey: comment.IdentityKey,
            EntityCorrelationKey: comment.EntityCorrelationKey,
            CorrelationKey: comment.CorrelationKey,
            TaskId: comment.TaskId,
            ActivityType: comment.ActivityType,
            WorkflowSetId: comment.WorkflowSetId,
            "@Type": "AccountComment"
        }
        const request = this.http.put<any>(url, serializedComment).pipe(share());
        this.handleNormalPostPutRequest(AccountComment, request, accountContext);
        return request;
    }

    deleteComment(accountId: string, comment: AccountComment, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.basePutUrl(accountId, "Comments", comment.Id);

        const request = this.http.delete<any>(url).pipe(share());
        this.handleSoftDeleteRequest(AccountComment, request, accountContext);
        return request;
    }

    resurrectComment(accountId: string, comment: AccountComment, accountContext: AccountContext): Observable<Response> {
        const url = this.serviceUrls.basePutUrl(accountId, "Comments", comment.Id) + '/Resurrect';

        const request = this.http.put<any>(url, null).pipe(share());
        this.handleNormalPostPutRequest(AccountComment, request, accountContext);
        return request;
    }

    //#endregion
    public searchAccounts(searchString: string): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.accountsSearchTemplate;
        let request = this.http.get<any>(url + "?searchTerms=" + encodeURIComponent(searchString)).pipe(share());

        this.handleRequestWithoutResponse(request);
        return request;
    }

    public getLaunchUrl(accountId: string, transactionId: string): Observable<Response> {

        var self = this;
        var url = this.serviceUrls.TransactionLaunchUrl(accountId, transactionId);
        let request = this.http.get<any>(url).pipe(share());
        request.subscribe({
            next: response => { },
            error: error => {
                if (error["status"] && (error["status"] == 404 || error["status"] == 500))
                    self.handleError(error);
                else
                    console.error(error);
            }
    });
        return request;
    }

    public updatePolicySetTerm(policySetId: string, termDTO: PolicyTermDTO, accountContext: AccountContext) {
        var url = this.serviceUrls.basePutUrl(accountContext.accountId, "PolicySets", policySetId) + "/Term";
        let request = this.http.put<any>(url, termDTO).pipe(share());
        this.handleNormalPostPutRequest(PolicySet, request, accountContext);
        return request;
    }

    public changeSelectionStatus(accountId: string, policySetId: string, workflowId: string, 
        productDTO: StartWorkflowDTOProduct, accountContext: AccountContext): Observable<Response> {
        var url = this.serviceUrls.ChangeSelection(accountId, policySetId);

        const params = new HttpParams()
            .set("workflowId", workflowId);
        let request = this.http.put<any>(url, productDTO, { params }).pipe(share());
        this.handleNormalPostPutRequest(Transaction, request, accountContext);
        return request;
    }

    public loadWorkflowSets(context: AccountContext, isInteractive: boolean, accountId?: string): Observable<Response> {
        accountId = accountId || context.account?.Id;

        if (!accountId) {
            this.loggingService?.logWarn("Was asked to get workflow sets without providing a valid accountId");
            return of(null);
        }

        const interactiveHeader = this.getUserInteractionHeader(isInteractive);
        const url = this.serviceUrls.WorkflowSets(accountId || context.account.Id);
        const request = this.http.get<any>(url, { headers: interactiveHeader}).pipe(share());

        this.handleNormalGetRequest(WorkflowSet, request, context);
        return request;
    }

    public loadRiskDetails(context: AccountContext, workflowSetId: string, showAllRules: boolean, includeDetails: boolean): Observable<Response> {
        const url = this.serviceUrls.riskDetails(workflowSetId) + "?includeDetails=" + includeDetails + "&showAllRules=" + showAllRules;
        const request = this.http.get<any>(url).pipe(share());   
        this.handleNormalGetRequest(RuleSetRiskAssessment, request, context);
        return request;
    }
}

export interface PolicyTermDTO {
    EffectiveDate: ContractDate;
    ExpirationDate: ContractDate;
}

export interface CommissionDTO {
    Commission: number;
}

export interface AccountTagsDTO {
    Tags: string[];
}