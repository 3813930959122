import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import _ from "lodash";
import { FormValueChangeEvent, SmFormFieldVM, SmFormRowVM, SmFormState, SmFormTypes } from "../sm-form.model";


@Component({
    selector: 'sm-form-currency-block',
    templateUrl: './sm-form-currency-block.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormCurrencyBlockComponent {
    // This is a special type of 'Field'. Its really two fields (select + money) that 
    // operate together. The selection in the select field is the currency used for display
    // in the money field. 

    @Input() model: SmFormRowVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();

    public formStateCopy: SmFormState;
    public moneyField: SmFormFieldVM;
    public currencyField: SmFormFieldVM;


    ngOnInit() {
        this.init();
    }

    ngOnChanges() {
        this.init();
    }

    private init() {
        if (this.model.Fields?.length) {
            this.currencyField = _.find(this.model.Fields, f => f.FieldType == SmFormTypes.Select);
            this.moneyField = _.find(this.model.Fields, f => f.FieldType == SmFormTypes.Money);
            
            this.updateCurrencyOnField();
        }
    }

    public onCurrencyFieldChange(e: any) {
        this.updateCurrencyOnField();

        this.onValueChanged.emit(e);
    }
    
    private updateCurrencyOnField() {
        if (!this.moneyField?.Options)
            this.moneyField.Options = {};

        this.moneyField.Options.Currency = this.currencyField.Value;

        // Trigger changes for the children by creating a copy of the form state
        // We can't create a copy of the field as that messes with references.
        // This isn't an ideal approach, but it works. 
        this.formState = {...this.formState};
    }
}