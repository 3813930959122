import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SmFormService } from "../../sm-form-service";
import { SmFormFieldVM, SmFormState } from "../../sm-form.model";

@Component({
    selector: 'sm-form-previous-value',
    templateUrl: './sm-form-previous-value.component.html',
    styleUrls: ['./sm-form-previous-value.component.scss']
})
export class SmFormPreviousValueComponent {

    constructor(private service: SmFormService) { }

    @Input() model: SmFormFieldVM;
    @Input() formState: SmFormState;

    @Output() revertToPreviousValue: EventEmitter<any> = new EventEmitter();

    public getPreviousValue() {
        return this.service.formatFieldValue(this.model.PreviousValue, this.model.FieldType, this.model.Options, this.formState);
    }

    public fieldHasPreviousValue() {
        return this.service.fieldHasPreviousValue(this.model, this.formState);
    }
}