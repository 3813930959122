import { Component, Input } from "@angular/core";
import _ from "lodash";
import { SmFormService } from "./sm-form-service";
import { SmFormStaticElementVM } from "./sm-form.model";

@Component({
    selector: 'sm-form-static-element',
    templateUrl: './sm-form-static-element.component.html',
})
export class SmFormStaticElementComponent  {

    @Input() model: SmFormStaticElementVM;

    constructor(public service: SmFormService) { }

    ngOnInit() {
        this.service.getElementChanged().subscribe(e => {
            if (e.ElementId === this.model.Id) {
                this.model = _.clone(e.ElementVM as SmFormStaticElementVM);
            }
        });
    }
}