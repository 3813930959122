import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import _ from "lodash";
import { Subscription } from "rxjs";
import { SmFormService } from "./sm-form-service";
import { SmFormFieldVM, SmFormState, FormValueChangeEvent, SmFormTypes, SmElementChangedEvent } from "./sm-form.model";

@Component({
    selector: 'sm-form-field',
    templateUrl: './sm-form-field.component.html',
    styleUrls: ['./sm-form-field.component.scss']
})
export class SmFormFieldComponent  {

    @Input() model: SmFormFieldVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    // Propagate whenever a change happens that merits parent components to recheck statuses.
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();

    public SM_FORM_TYPES = SmFormTypes;

    public inputHasFocus: boolean = false;

    public incomingSyncTimeout: any;
    public outgoingInputTimeout: any;

    private elementChanged$: Subscription;

    constructor(public service: SmFormService,
        public cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.elementChanged$ = this.service.getElementChanged().subscribe(e => {
            if (e.ElementId == this.model.Id ) {
                this.clearIncoming();

                if (this.inputHasFocus) {       
                    this.incomingSyncTimeout = setTimeout(() => {
                        this.updateModel(e);
                        this.incomingSyncTimeout = null;
                    }, 1000);
                } else {
                    this.updateModel(e);
                } 
            }
        });
    }

    ngOnDestroy() {
        this.elementChanged$?.unsubscribe();
    }

    // When a value is changed by an 'input' event,
    // the user will still have focus on the field, so debounce
    // the call so we don't send an API call for every letter typed. 
    public valueChanged(e: FormValueChangeEvent) {
        // If the field is readonly or disabled, don't send any event. 
        if (this.model.Disabled)
            return;

        // Cancel the existing outgoing API call
        this.clearOutgoing();

        // If we have an sync message that came in while the user is still typing,
        // ignore that event as the user is overriding it.
        this.clearIncoming();

        if (e.Type == 'input') {
            // This is called when the value is updated on the 'input' event
            // The user has not left the field, so we don't want to send 
            // the change event immediately. 
            this.outgoingInputTimeout = setTimeout(() => {
                this.clearIncoming();

                this.onValueChanged.emit(e);
                this.outgoingInputTimeout = null;
            }, 1000);     
        } else {
            this.onValueChanged.emit(e);   
        }  
    }

    private clearIncoming() {
        if (this.incomingSyncTimeout) {
            clearTimeout(this.incomingSyncTimeout);
            this.incomingSyncTimeout = null;
        }      
    }

    private clearOutgoing() {
        if (this.outgoingInputTimeout) {
            clearTimeout(this.outgoingInputTimeout);
            this.outgoingInputTimeout = null;
        }   
    }

    public updateModel(e: SmElementChangedEvent) {
        this.model = e.ElementVM as SmFormFieldVM;
        this.cdRef.detectChanges();
        this.onDetectChanges.emit();
    }

    public focusChanged(hasFocus: boolean) {
        this.inputHasFocus = hasFocus;
    }
}