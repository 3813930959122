import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SmDateFormats } from "@Shared/Pipes/sm-date.pipe";
import _ from "lodash";
import { Subscription } from "rxjs";
import { SmFormSectionModalComponent } from "./sm-form-section-modal.component";
import { SmFormService } from "./sm-form-service";
import { 
    SmFormSectionInstanceVM, SmFormState, FormValueChangeEvent, SmFormSectionVM, 
    SectionDeletedEvent, SmElementFocusedEvent, SmFormFieldVM, SmFormRowVM, SmFormTypes 
} from "./sm-form.model";

@Component({
    selector: 'sm-form-section-instance',
    templateUrl: './sm-form-section-instance.component.html',
})
export class SmFormSectionInstanceComponent  {

    @Input() model: SmFormSectionInstanceVM;
    @Input() parentId: string;
    @Input() labels: string[] = [];
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @ViewChild('instance') instanceElementRef: ElementRef;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onSectionAdded: EventEmitter<SmFormSectionVM> = new EventEmitter();
    @Output() onSectionDeleted: EventEmitter<SectionDeletedEvent> = new EventEmitter();
    // Propagate whenever a change happens that merits parent components to recheck statuses.
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();

    public hasError = false;

    public elementChanged$: Subscription;
    public openSectionInstance$: Subscription;
    public sectionDeleted$: Subscription;
    public elementFocused$: Subscription;

    constructor(private modalService: NgbModal,
        private service: SmFormService,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.elementChanged$ = this.service.getElementChanged().subscribe(e => {
            if (e.ElementId === this.model.Id) {
                this.model = e.ElementVM;
                this.cdRef.detectChanges();
                this.updateStatuses();
            }
        });

        this.sectionDeleted$ = this.service.getSectionDeleted().subscribe(e => {
            if (e.InstanceId === this.model.Id)
                this.updateStatuses();
        });

        this.openSectionInstance$ = this.service.getOpenSectionInstance().subscribe(e => {
            if (e.InstanceId === this.model.Id) {
               this.openSectionInstance();
            }
        });

        this.elementFocused$ = this.service.getElementFocused().subscribe(e => {
            this.handleElementFocused(e);
        });

        this.updateStatuses(false);
    }

    ngOnDestroy() {
        this.elementChanged$?.unsubscribe();
        this.openSectionInstance$?.unsubscribe();
        this.elementFocused$?.unsubscribe();
    }

    private handleElementFocused(e: SmElementFocusedEvent) {
        if (e.Type == 'Field') {
            if (e.Id == this.model.Id || _.some(this.getFieldsAndRowFields(), i => i.Id == e.Id)) {
                this.instanceElementRef.nativeElement.scrollIntoViewIfNeeded(true);

                // We need to wait until the scroll is completed.
                // There is not a good way to do this, but most browsers
                // (other than Chrome) max out at 500ms.
                setTimeout(() => this.openSectionInstance(e.Id), 500);
            }


        } else {
            if (e.Id == this.model.Id) {
                this.model.ToggledToHide = false;
                this.cdRef.detectChanges();

                if (e.Type == 'ParentSection') {
                    // If we got here because of a 'ParentSection' issue, then we know its a min or max issue.
                    // However, we got directed to the parent. Find the first child with a min or max issue.
                    // If we can find it, redirect this message to it.
                    var childRepeatableSectionWithMinOrMaxError = _.find(this.model.Items, i => {
                        return i.ElementType == 'Section'
                            && (i as SmFormSectionVM).Repeatable
                            && (
                                this.service.repeatableSectionHasMaxError(i as SmFormSectionVM)
                                || this.service.repeatableSectionHasMinError(i as SmFormSectionVM)
                            );
                    }) as SmFormSectionVM;

                    if (childRepeatableSectionWithMinOrMaxError) {
                        // We found a child with the min/max error, show and set focus to it.
                        childRepeatableSectionWithMinOrMaxError.ToggledToHide = false;
                        this.cdRef.detectChanges();
                        this.service.sendElementFocused(childRepeatableSectionWithMinOrMaxError.Id, 'Section');
                    } else {
                        // We couldn't find it, just scroll to the parent (this)
                        this.instanceElementRef.nativeElement.scrollIntoViewIfNeeded(true);
                    }
                } else if (e.Type == 'Section') {
                    // This scenario does not currently have a use case in our application, but we still handle it.

                    // We want focus on this section, so scroll to it.
                    this.instanceElementRef.nativeElement.scrollIntoViewIfNeeded(true);
                }
            }
        }
    }

    hasPreviousValue(label: string) {
        return this.getValueOfItemWithLabel(label) != this.getPreviousValueOfItemWithLabel(label);
    }

    getValueOfItemWithLabel(label: string) {
        var field = this.findFieldAndUpdateOptions(label);
        if (!field)
            return "";
        return this.service.formatFieldValue(field?.Value, field.FieldType, field.Options, this.formState, field.Prefix, field.Suffix);
    }

    getPreviousValueOfItemWithLabel(label: string) {
        var field = this.findFieldAndUpdateOptions(label);
        if (!field)
            return "";
        return this.service.formatFieldValue(field?.PreviousValue, field.FieldType, field.Options, this.formState, field.Prefix, field.Suffix);
    }

    private findFieldAndUpdateOptions(label: string): SmFormFieldVM {
        var field = _.find(this.model.Items, i => i.ElementType == 'Field' && (i as SmFormFieldVM).Label == label) as SmFormFieldVM;
        if (!field) {
            var rows = _.filter(this.model.Items, i => i.ElementType == 'Row') as SmFormRowVM[];
            if (rows?.length) {
                var fieldsUnderRows = _.flatten(_.map(rows, r => r.Fields)) as SmFormFieldVM[];
                field = _.find(fieldsUnderRows, f => (f as SmFormFieldVM).Label == label) as SmFormFieldVM;

                if (field?.SpecialType == 'Currency' && field.FieldType == SmFormTypes.Money) {
                    var currencyField = _.find(fieldsUnderRows, f => f.SpecialType == 'Currency' && f.FieldType == SmFormTypes.Select);
                    if (!field?.Options)
                        field.Options = {};
                    field.Options = {
                        ...field.Options,
                        Currency: currencyField?.Value
                    }
                }
            }
        }

        if (!field)
            return null;

        // Since we are in a 'grid', update the date format
        if (!field?.Options)
            field.Options = {};
        field.Options = {
            ...field.Options,
            DateFormat: SmDateFormats.ShortGrid
        };

        return field;

    }

    openSectionInstance(elementToFocus: string = null) {
        if (this.modalService.hasOpenModals())
            return;

        const modal = this.modalService.open(SmFormSectionModalComponent, { backdrop: 'static', centered: true });
        const modalInstanceComponent = modal.componentInstance as SmFormSectionModalComponent;
        modalInstanceComponent.model = this.model;
        modalInstanceComponent.elementToFocus = elementToFocus;
        modalInstanceComponent.formState = this.formState;

        modal.componentInstance.onValueChanged.subscribe(e => {
            this.onValueChanged.emit(e);
        });

        modal.componentInstance.onDetectChanges.subscribe(e => {
            this.updateStatuses();
        });
    }

    getChildrenSections() {
        return _.filter(this.model.Items, i => (i as SmFormFieldVM)?.ElementType === 'Section');
    }

    onDeleteSelf() {
        this.onSectionDeleted.emit({ InstanceId: this.model.Id, InstancePartitionKey: this.model.PartitionKey, ContainingSectionId: this.parentId });
    }

    private updateStatuses(sendOutput: boolean = true) {
        if (this.model.ParticipationStatus === "Deleted") {
            this.hasError = false;
        } else  {
            this.hasError = this.service.childrenHaveAtLeastOneError(this.model.Items);
        }

        if (!this.model.ParticipationStatus && this.service.itemsHavePreviousValue(this.model.Items, this.formState)) {
            this.model.ParticipationStatus = 'Changed';
        } else if (this.model.ParticipationStatus == 'Changed' && !this.service.itemsHavePreviousValue(this.model.Items, this.formState)) {
            this.model.ParticipationStatus = null;
        }

        this.cdRef.detectChanges();
        if (sendOutput)
            this.onDetectChanges.emit();
    }

    public getFieldsAndRows(): (SmFormFieldVM | SmFormRowVM)[] {
        return this.model.Items.filter(i => i.ElementType == 'Field' || i.ElementType == 'Row') as (SmFormFieldVM | SmFormRowVM)[];
    }

    public getFieldsAndRowFields(): SmFormFieldVM[] {
        var fieldsAndRows = this.getFieldsAndRows();
        var fields = _.map(fieldsAndRows, e => {
            if (e.ElementType == 'Field')
                return e as SmFormFieldVM;
            else if (e.ElementType == 'Row')
                return (e as SmFormRowVM).Fields;
            return null;
        });
        return _.flatten(fields);
    }

    public formatNegatives(label): boolean {
        var field = _.find(this.model.Items, i => (i as SmFormFieldVM).Label == label) as SmFormFieldVM;
        if (!field)
            return false;

        return field.Options?.DisplayNegativesInRed && Number(field.Value) < 0;
    }
}